// 1 - Settings
@import "1_settings/sizing";
@import "1_settings/colors";
@import "1_settings/layout";
@import "1_settings/shadow";
@import "1_settings/border-radius";
@import "1_settings/gradient";
@import "1_settings/fonts";
@import "1_settings/icons";

// 2 - Tools
// General
@import "2_tools/general/unitless-sizing";
@import "2_tools/general/breakpoints";

// Spacing
@import "2_tools/spacing/margin";
@import "2_tools/spacing/padding";

// Resets
@import "2_tools/resets/button-reset";
@import "2_tools/resets/link-reset";
@import "2_tools/resets/list-reset";

// Helpers
@import "2_tools/helpers/aspect-ratio";
@import "2_tools/helpers/clearfix";
@import "2_tools/helpers/screen-reader-text";
@import "2_tools/helpers/select-last-row";
@import "2_tools/helpers/triangle";
@import "2_tools/helpers/transition";
@import "2_tools/helpers/mobile-show";
@import "2_tools/helpers/mobile-hide";
@import "2_tools/helpers/focus-style";

// 3 - Generic
@import "../../node_modules/normalize.css/normalize";
@import "3_generic/box-sizing";
@import "3_generic/reset";
@import "3_generic/font";

// 4 - Elements
@import "4_elements/page";
@import "4_elements/image";
@import "4_elements/link";
@import "4_elements/address";

// 5 - Objects
@import "5_objects/page-wrapper";
@import "5_objects/container";

// 6 - Components
//node modules
@import "../../node_modules/swiper/swiper";
@import "../../node_modules/@tarekraafat/autocomplete.js/dist/css/autoComplete.css";

//layout
@import "6_components/layout/navbar";
@import "6_components/layout/toolbar";
@import "6_components/layout/logo";
@import "6_components/layout/section";
@import "6_components/layout/footer";
@import "6_components/layout/header";

// elements
@import "6_components/elements/icon";
@import "6_components/elements/headline";
@import "6_components/elements/text";
@import "6_components/elements/list";
@import "6_components/elements/button";
@import "6_components/elements/link";
@import "6_components/elements/figure";

// landingpage
@import "6_components/landingpage-2023/lp23-base";
@import "6_components/landingpage-2023/lp23-section";
@import "6_components/landingpage-2023/lp23-gradient";
@import "6_components/landingpage-2023/lp23-typewriter";
@import "6_components/landingpage-2023/lp23-header";
@import "6_components/landingpage-2023/lp23-headline";
@import "6_components/landingpage-2023/lp23-logo-decoration";
@import "6_components/landingpage-2023/lp23-privacy";
@import "6_components/landingpage-2023/lp23-features";
@import "6_components/landingpage-2023/lp23-quotes";
@import "6_components/landingpage-2023/lp23-business-card";
@import "6_components/landingpage-2023/lp23-footer";
@import "6_components/landingpage-2023/lp23-card-grid";
@import "6_components/landingpage-2023/lp23-stage-video";
@import "6_components/landingpage/lp-base";
@import "6_components/landingpage/lp-container";
@import "6_components/landingpage/lp-section";
@import "6_components/landingpage/lp-typo";
@import "6_components/landingpage/lp-button";
@import "6_components/landingpage/lp-teaser";
@import "6_components/landingpage/lp-icon-teaser";
@import "6_components/landingpage/lp-checklist";
@import "6_components/landingpage/lp-content";
@import "6_components/landingpage/lp-about-us";
@import "6_components/landingpage/lp-answers";
@import "6_components/landingpage/lp-logo-decoration";

// 7 - Trumps
@import "7_trumps/flex";
@import "7_trumps/visibility";
@import "7_trumps/typography";
