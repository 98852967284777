.lp23-quotes {
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(md) {
    margin: 4rem auto 8rem auto;
  }

  &__wrapper {
    max-width: 800px;
    margin-top: 4rem;
    transform: scale(1);
    opacity: 1;
    transition: transform 0.8s ease-out, opacity 0.8s ease-out;

    &.hide {
      transform: scale(0.9);
      opacity: 0.6;
    }
  }

  &__quote {
    font-size: 1.2rem;

    @include breakpoint(sm) {
      font-size: 1.8rem;
    }

    &::before {
      content: "»";
      color: $color-violet;
    }

    &::after {
      content: "«";
      color: $color-salmon;
    }
  }

  &__source {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.2;
    font-weight: 300;
    justify-content: center;
    text-align: left;
  }

  &__role {
    display: block;

    &,
    a {
      color: $color-white;
    }

    a {
      text-decoration: underline;
    }

    a:hover,
    a:focus {
      color: $color-salmon;
      text-decoration: none;
    }
  }

  &__image {
    width: 48px;
    height: 48px;
    margin-right: 1rem;
    flex: 0 0 auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
