.lp23-logo-decoration {
  position: absolute;
  display: none;
  top: 0;
  left: 50%;
  margin-left: -350px;
  width: 1000px;
  height: 692px;
  overflow: hidden;
  z-index: 0;

  @include breakpoint(lg) {
    display: block;
  }

  img {
    width: 1000px;
    height: 692px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}
