@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.lp23-typewriter {
  position: relative;
  display: inline-flex;
  grid-gap: 0.5rem;
  font-weight: 600;

  &::after {
    content: "";
    width: 3px;
    height: 1em;
    display: inline-flex;
    background-color: $color-pastelgrape;
    animation: 1s blink-animation 1s steps(2, start) infinite;
  }
}
