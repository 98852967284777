html.lp23 {
  font-size: 16px;

  @include breakpoint(lg) {
    font-size: 18px;
  }
}

.lp23-page {
  background-color: $color-darkblue;
  color: $color-white;

  .page__container {
    overflow: hidden;
    padding-bottom: 0;
  }

  main {
    position: relative;
  }

  strong {
    font-weight: 600;
  }
}
