.lp23-business-card {
  background-color: $color-darkblue;
  background-image: url("../../images/answers/business-card.svg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  font-family: $font-realtime;
  position: relative;
  transform: rotate(1.5deg);
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 0;
  padding-bottom: 57.75%;

  a {
    color: $color-white;

    &:hover {
      color: $color-salmon;
    }
  }

  &__container {
    overflow: hidden;
    position: absolute;
    border-radius: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 7.5%;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(xs) {
      font-size: 1.2rem;
    }

    @include breakpoint(xs) {
      font-size: 1rem;
    }

    @include breakpoint(md) {
      font-size: 1.2rem;
    }
  }

  &__person {
    display: grid;
    grid-column-gap: 0.5rem;
    align-items: center;
    grid-template-columns: 13.75% 1fr;

    @include breakpoint(xs) {
      grid-column-gap: 2rem;
    }
  }

  &__avatar {
    border-radius: 12px;
    overflow: hidden;

    img {
      display: block;
    }
  }

  &__name {
    line-height: 1.6;
  }

  &__position {
    opacity: 0.8;
    font-size: 0.9em;
  }
}
