.lp23-footer {
  padding: 1rem 0;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  @include breakpoint(md) {
    padding: 2rem 0;
  }

  &--with-wordmark {
    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: $lp-container-extended;
      width: 100%;

      @include breakpoint(lg) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  &__wordmark {
    padding: 0.5rem 0;
  }

  &__list-wrapper {
    @include list-reset();

    font-family: $font;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: ". .";
    text-align: center;

    @include breakpoint(xs) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: ". . .";
    }

    @include breakpoint(md) {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }
  }

  &__link {
    @include link-reset();

    display: block;
    font-family: $font;
    padding: 0.5rem 1rem;
    border: 2px solid transparent;
    font-size: $font-text-xs;
    white-space: nowrap;
    color: $color-white;

    &:active,
    &:hover,
    &:focus {
      color: $color-salmon;
    }
  }
}
