.lp-answers {
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    @include breakpoint(lg) {
      justify-content: space-between;
      padding: 2rem;
    }

    &__logo {
      .logo {
        margin: 0;
      }
    }

    &__cta {
      display: none;

      @include breakpoint(lg) {
        display: flex;
      }

      .lp-button {
        margin: 0;
      }
    }
  }

  &-stage-video {
    position: relative;
    margin: 2rem auto;
    max-width: 90%;

    &__pink-deco,
    &__blue-deco {
      position: absolute;
      display: block;
      width: 40%;
      height: 0;
      padding-bottom: 40%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      z-index: 0;
    }

    &__pink-deco {
      left: -5%;
      top: -10%;
      background-image: url("../../images/kringel-pink.svg");
    }

    &__blue-deco {
      right: -5%;
      bottom: -15%;
      background-image: url("../../images/kringel-blue.svg");
    }

    &__play {
      display: none;
      border: 0;
      border-radius: 50%;
      background-color: transparent;
      background-image: url("../../images/answers/icon-play.svg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 12%;
      height: 0;
      padding-bottom: 12%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      z-index: 20;

      &.is-visible {
        display: block;
      }
    }

    &__video {
      position: relative;
      z-index: 10;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      border-radius: 40px;
      width: 100%;
      background-color: $color-white;
      overflow: hidden;
      height: 0;
      padding-top: 56.25%;

      video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-quote {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 40%;
      height: 0;
      padding-bottom: 40%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: -3%;
      top: -10%;
      background-image: url("../../images/kringel-yellow.svg");
      z-index: 0;

      @include breakpoint(md) {
        left: 3%;
      }
    }

    &__container {
      margin: 5%;
      background-color: $color-white;
      box-shadow: -10px 20px 10px -11px rgba(0, 0, 0, 0.1);
      padding: 2rem;
      position: relative;
      z-index: 10;

      @include breakpoint(md) {
        margin: 15%;
        padding: 2rem 1.5rem;
      }
    }

    &__quote {
      font-size: 1.5rem;
      line-height: 1.2;
      font-weight: 300;

      @include breakpoint(sm) {
        margin-left: calc(80px + 1rem);
      }

      strong {
        font-weight: 700;
      }
    }

    &__source {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      font-size: 1;
      line-height: 1.2;
      font-weight: 300;

      @include breakpoint(sm) {
        font-size: 1.125rem;
      }
    }

    &__role {
      display: block;

      &,
      a {
        color: $color-middlegray-3;
      }

      a {
        text-decoration: underline;
      }

      a:hover,
      a:focus {
        color: $color-charcoal;
        text-decoration: none;
      }
    }

    &__image {
      width: 80px;
      height: 80px;
      margin-right: 1rem;
      flex: 0 0 auto;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    img {
      margin: 1rem;
      max-height: 50px;
      flex: 0 0 calc(50% - 2rem);
      max-width: calc(50% - 2rem);

      @include breakpoint(xs) {
        flex: 0 0 calc(33.3333% - 2rem);
        max-width: calc(33.3333% - 2rem);
      }

      @include breakpoint(sm) {
        flex: 0 0 calc(20% - 2rem);
        max-width: calc(20% - 2rem);
      }
    }
  }

  &-contact-card {
    background-color: $color-snow;
    border-radius: 1.5rem;
    padding: 1rem;

    @include breakpoint(xl) {
      display: grid;
      grid-template-columns: auto 360px;
      grid-gap: 1rem;
      align-items: center;
    }

    @include breakpoint(md) {
      padding: 1.5rem;
    }

    @include breakpoint(lg) {
      padding: 2rem;
    }

    /* stylelint-disable no-descending-specificity */
    a {
      color: $color-charcoal;
    }

    a:hover,
    a:focus {
      color: $color-black;
    }

    &__headline {
      @include breakpoint(xl-max) {
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .lp-headline {
        margin-top: 0.5rem;
      }
    }

    &__face {
      picture {
        max-width: 120px;
        display: block;
        margin: 0 auto;
        border: 12px solid white;
        border-radius: 50%;

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }

    &__contact {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      align-items: center;
      max-width: 270px;
      margin: 0 auto;

      @include breakpoint(xs) {
        grid-template-columns: 2fr 3fr;
        width: 270px;
        max-width: none;
      }

      @include breakpoint(sm) {
        width: 360px;
      }
    }
  }
}
