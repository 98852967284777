.lp23-privacy {
  display: block;
  margin: 3rem 0;

  @include breakpoint(md) {
    margin: 5rem 0;
  }

  hr {
    display: block;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(39, 39, 42, 0) 0%, #27272a 51.56%, rgba(39, 39, 42, 0) 100%);
    border: 0;
    margin: 2rem 0;
  }

  &__fingerprint {
    margin: 1rem auto;

    @include breakpoint(sm) {
      margin: 1rem;
    }

    &,
    svg {
      width: 64px;
      height: 64px;

      @include breakpoint(sm) {
        width: 96px;
        height: 96px;
      }
    }
  }

  &__list {
    @include list-reset;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(sm) {
      flex-direction: row;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;
    line-height: 1.4;
    color: $color-white;

    @include breakpoint(sm) {
      text-align: left;

      &:first-child {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }

      &:last-child {
        order: 3;
      }
    }
  }

  &__icon {
    display: block;
    margin-right: 0.5rem;

    &,
    svg {
      width: 24px;
      height: 24px;
    }
  }
}
