.lp23-card-grid {
  grid-gap: 1rem;
  display: flex;
  flex-direction: column;

  @include breakpoint(md) {
    display: grid;
    grid-template-areas:
      "grid-left grid-top"
      "grid-left grid-bottom";
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 40% auto;
    margin-bottom: 15%;
  }

  &__left {
    grid-area: grid-left;
  }

  &__top {
    grid-area: grid-top;
  }

  &__bottom {
    grid-area: grid-bottom;
  }

  &__subgrid {
    display: grid;
    grid-gap: 1rem;
    height: 100%;
  }

  &__card {
    position: relative;
    background-color: $color-darkblue;
    border: 1px solid $color-darkgrey;
    border-radius: 12px;
    overflow: hidden;
    font-weight: 700;

    h4 {
      font-weight: 700;
    }

    &:not(.hide-shadow)::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      box-shadow: -10px -10px 10px 0 inset $color-darkblue;
    }

    &.has-padding {
      padding: 1rem;
    }

    &.max-height {
      @include breakpoint(sm-max) {
        max-height: 180px;
      }
    }

    &.is-centered {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .small-text {
      font-size: 0.7em;
    }

    .text-container {
      position: relative;
      padding: 1rem 1rem 0 1rem;
      z-index: 1;
    }

    .image-container {
      position: relative;
      z-index: 0;

      &.absolute-left {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &.padding-left {
        padding-left: 1rem;
      }

      &.padding-top {
        padding-top: 1rem;
      }

      &.padding-bottom {
        padding-bottom: 1rem;
      }

      &.padding-right {
        padding-right: 1rem;
      }

      &.max-width {
        max-width: 175px;
      }
    }

    .emoji {
      display: block;
      font-size: 2rem;
    }

    .max-width {
      max-width: 70%;
      margin: 0 auto;
    }
  }

  .categories {
    .image-container {
      padding: 1rem 0 1rem 1rem;

      @include breakpoint(sm) {
        width: 200%;
      }

      @include breakpoint(md) {
        width: 100%;
      }
    }
  }

  .main-title {
    @include breakpoint(md-max) {
      padding: 2rem;
      min-height: 120px;
    }

    picture,
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      padding: 0;

      @include breakpoint(md-max) {
        object-fit: contain;
      }
    }
  }

  .instant-answers {
    @include breakpoint(sm-max) {
      max-height: 180px;
    }

    .image-container {
      padding-left: 1rem;

      @include breakpoint(md) {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      picture,
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .all-devices {
    @include breakpoint(sm-max) {
      min-height: 180px;
    }

    .image-container {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;

      picture,
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .green-electricity {
    min-height: 350px;

    @include breakpoint(sm) {
      min-height: 180px;
    }

    .image-container {
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      picture,
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}

.lp23-card-grid-left {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(sm) {
    display: grid;
    grid-area: grid-left;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 2fr;
  }

  @include breakpoint(md) {
    grid-template-rows: 1fr 1fr 2fr;
    grid-template-columns: 1fr;
  }

  & > * {
    width: 100%;

    @include breakpoint(xs) {
      width: calc(50% - 0.5rem);

      &:last-child {
        width: 100%;
      }
    }

    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.lp23-card-grid-top {
  grid-area: grid-top;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @include breakpoint(sm) {
    grid-template-columns: 60% auto;
    grid-template-rows: 1fr;
  }
}

.lp23-card-grid-bottom {
  grid-area: grid-bottom;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  @include breakpoint(sm) {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
  }
}

.lp23-card-grid-bottom-left {
  display: grid;
  grid-template-areas:
    "grid-bottom-left-top"
    "grid-bottom-left-left"
    "grid-bottom-left-right";
  grid-template-rows: auto 1fr 1fr;

  @include breakpoint(xs) {
    display: grid;
    grid-template-areas:
      "grid-bottom-left-top grid-bottom-left-top"
      "grid-bottom-left-left grid-bottom-left-right";
    grid-template-rows: 40% auto;
  }

  @include breakpoint(md) {
    grid-template-columns: 1fr 2fr;
  }

  &__top-card {
    grid-area: grid-bottom-left-top;
  }

  &__left-card {
    grid-area: grid-bottom-left-left;
  }

  &__right-card {
    grid-area: grid-bottom-left-right;
  }
}

.lp23-card-grid-bottom-right {
  display: grid;
  grid-template-areas:
    "grid-bottom-right-left"
    "grid-bottom-right-right"
    "grid-bottom-right-bottom";
  grid-template-rows: 1fr 1fr auto;

  @include breakpoint(xs) {
    display: grid;
    grid-template-areas:
      "grid-bottom-right-left grid-bottom-right-right"
      "grid-bottom-right-bottom grid-bottom-right-bottom";
    grid-template-rows: 30% auto;
  }

  &__left-card {
    grid-area: grid-bottom-right-left;
  }

  &__right-card {
    grid-area: grid-bottom-right-right;
  }

  &__bottom-card {
    grid-area: grid-bottom-right-bottom;
  }
}
