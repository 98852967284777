.lp23-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  position: relative;
  z-index: 1000;

  @include breakpoint(sm) {
    flex-direction: row;
  }

  @include breakpoint(lg) {
    padding: 2rem;
  }

  &__logo {
    .logo {
      margin: 0;

      svg {
        width: 100%;
        max-width: 200px;
        height: auto;
      }
    }
  }

  &__cta {
    display: flex;
  }

  .lp23-phone-number {
    color: $color-white;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
  }
}
