.lp23-features {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  margin: 2rem 0;

  @include breakpoint(xs) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint(sm) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }

  &__feature {
    text-align: left;

    .lp23-headline {
      display: flex;
      align-items: center;
      grid-gap: 0.5rem;
    }
  }
}
