.lp23-stage-video {
  position: relative;
  margin: 2rem auto;
  max-width: 90%;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: "";
    width: 100%;
    height: 50%;
    background: #09062d;
    filter: blur(200px);
  }

  &__play {
    display: none;
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    background-image: url("../../images/answers/icon-play.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 12%;
    height: 0;
    padding-bottom: 12%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 20;

    &.is-visible {
      display: block;
    }
  }

  &__video {
    position: relative;
    z-index: 10;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    height: 0;
    padding-top: 56.25%;

    @include breakpoint(sm) {
      border-radius: 40px;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
