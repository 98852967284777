.lp23-headline {
  margin-bottom: 1rem;
  color: $color-white;
  line-height: 1.2;
  font-weight: 500;

  &--h2 {
    font-size: 1.4rem;

    @include breakpoint(sm) {
      font-size: 2.65rem;
    }
  }

  &--h3 {
    font-size: 1.2rem;

    @include breakpoint(sm) {
      font-size: 2.2rem;
    }
  }

  &--h4 {
    font-size: 1.05rem;

    @include breakpoint(sm) {
      font-size: 1.12rem;
    }
  }

  &--kicker {
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.2;
    margin: 2rem auto;

    @include breakpoint(sm) {
      font-size: 3.5rem;
    }

    strong {
      background: linear-gradient(270deg, $color-salmon 0%, $color-violet 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
}
